.quizWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}
.quizTitle{
    font-weight: 700;
    font-size: 32px;
    color: #0B0B0B;
    padding-bottom: 10px;
}

.quizContainer{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    max-height: 100%;
    flex-wrap: wrap;
}


.quizItem{
    position: relative;
    margin: 0 50px 30px 0;
    width: calc(33.3% - 50px);
}

.quizItem:nth-child(n-3){
    margin: 0 50px 0 0;
}

.quizItem__wrapper{
    position: relative;
    border-radius: 10px;
    max-width: 480px;
    overflow: hidden;
    cursor: pointer;
    background: none;
}


.quizItem__wrapper_active{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background: none;
}

.quizItem__title{
    color: #3C4445;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
}

.quizItem__img{
    width: 100%;

    object-fit: contain;
}

.quizItem__wrapperPoint{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #F4E4ba;
    top: 7%;
    right: 7%;
    background-color: aqua;
    border-radius: 50%;

}
.quizItem__point{
    position: absolute;
    width: 22px;
    height: 22px;
    top: 20px;
    right:20px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #495456
}

.quizItem__point.active:before{
    content: "";
    position: absolute;
    background: #1875FF;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

@media (max-width: 1024px) {
    .quizItem{
        position: relative;

        margin: 0 15px 15px 0;
        width: calc(33.3% - 15px);
    
       
    }
}

@media (max-width: 832px) {
    .quizTitle{
        font-size: 18px;
        padding-bottom: 30px;
    }

    .quizItem:nth-child(n-3){
        margin: 0;
    }

    .quizItem{
        position: relative;
        width: 100%;
        margin: 0;
    }

    .quizItem__title{
        font-size: 16px;
        padding: 2px 0 2px 35px;
        margin-top: 0;
    }
    .quizItem__img{
        display: none;
    }

    .quizItem__point{
        top: 0;
        right: auto;
        left: 0;
    }
    .quizContainer{
        gap: 30px;
    }
}