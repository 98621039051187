.quizWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}
.quizTitle{
    font-weight: 700;
    font-size: 32px;
    color: #0B0B0B;
    padding-bottom: 30px;
}

.quizContainer{
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    width: 100%;
    max-height: 100%;
    flex-wrap: wrap;
    gap: 40px 100px;
}

.quizItem{
    position: relative;
    display: flex;
    align-items: center;
    width: calc(50% - 50px);
}



.quizItem__wrapper{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background: none;
   
}

.quizItem__point{
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: 15px;
    border-radius: 50%;
    border: 1px solid #495456
}

.quizItem__point.active:before{
    content: "";
    position: absolute;
    background: #1875FF;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.quizItem__pointIn{
    width: 9px;
    height: 9px;
    border-radius: 50%;
}

.quizItem__title{
    display: flex;
    align-items: center;
    color: #3C4445;
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .quizItem{
        position: relative;

    
       
    }
}

@media (max-width: 784px) {
    .quizContainer{
        gap: 30px;
    }
    .quizItem{
        position: relative;
        padding: 0;
        width: 100%;
        font-size: 16px;
    }
    .quizTitle{
        font-size: 18px;
        padding-bottom: 30px;
    }
    .quizItem__title{
        font-size: 16px;
    }
}