.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modalForm {
  position: relative;
  background: #F9F9F9;
  border-radius: 15px;
  padding: 40px 60px;
  max-width: 1200px;
  height: 100%;
  width: 100%;
}

.modal.active {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

@media (max-width: 767px) {
  .modalForm {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .modal.active{
    align-items: flex-start;
  }

  .modalForm{
    width: 100%;
    max-width: 1024px;
    min-height: 100%;
    border-radius: 0;
  }
}
