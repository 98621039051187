.inputPhone{
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    background: none;
    padding: 0 30px;
    border: 1px solid #495456;
    background: #fff;
    border-radius: 100px;
}

.inputPhone.error{
    border: 1px solid #d10a34;
    color: #d10a34;
}

.inputPhone::placeholder{
    color: #bbb;
    cursor: pointer;
}

@media (max-width: 767px) {
    .inputPhone {
        height: 50px;
    }
}