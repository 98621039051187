@font-face {
  font-family: "Mulish";
  src: url("./static/fonts/Mulish-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("./static/fonts/Mulish-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("./static/fonts/Mulish-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url("./static/fonts/Mulish-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

*{
  font-family: "Mulish";
  font-weight: 500;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1em;
}

::-webkit-scrollbar {
  width:2px;
  background-color: #F9F9F9;
}

::-webkit-scrollbar-thumb {
  background-color: #0B0B0B;
  border-radius: 9em;
  box-shadow: inset 1px 1px 10px #ada284;
}

.App {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}


